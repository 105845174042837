<template lang="pug">
.container.bg-white.h-screen.p-5
  .flex.flex-wrap.overflow-hidden
    div(class='md:w-1/2  overflow-hidden')
      h3 ®
    div(class='md:w-1/2 overflow-hidden')
      label.block.mb-2.text-md.text-gray-600 Full Name
        input(type="text" placeholder="Full Name")
      label.block.mb-2.text-md.text-gray-600 Email
        input(type="email" placeholder="E-Mail")
      label.block.mb-2.text-md.text-gray-600 Phone
        input(type="text" placeholder="Phone")
      .flex.flex-wrap.overflow-hidden(class="-mx-2")
        div(class='w-1/3 px-2 overflow-hidden')
          label.block.mb-2.text-md.text-gray-600 Arrival/Flight Code
            input(type="text" placeholder="Full Name")
        div(class='w-1/3 px-2 overflow-hidden')
          label.block.mb-2.text-md.text-gray-600 Number Of People
            input(type="text" placeholder="Full Name")
        div(class='w-1/3 px-2 overflow-hidden')
          label.block.mb-2.text-md.text-gray-600 Currency
            input(type="text" placeholder="Full Name")
        div(class='w-1/2 px-2 overflow-hidden')
          label.block.mb-2.text-md.text-gray-600 Full Name
            input(type="text" placeholder="Full Name")
        div(class='w-1/2 px-2 overflow-hidden')
          label.block.mb-2.text-md.text-gray-600 Full Name
            input(type="text" placeholder="Full Name")
        div(class='w-1/2 px-2 overflow-hidden')
          label.block.mb-2.text-md.text-gray-600 Full Name
            input(type="text" placeholder="Full Name")
        div(class='w-1/2 px-2 overflow-hidden')
          label.block.mb-2.text-md.text-gray-600 Full Name
            input(type="text" placeholder="Full Name")
</template>

<script>
export default {
  layout: 'frame',
  data () {
    return {
      init: false
    }
  }
}
</script>

<style lang="scss">
  input[type="text"],input[type="number"],input[type="email"]{
    @apply w-full px-2 text-xs h-8 border-2 outline-none rounded-sm focus:shadow-lg focus:border-gray-600;
  }
</style>
